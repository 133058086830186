@import '../index.scss';

.branch-schedules {
    padding: 15px;
    border-left: 2px solid rgb(187, 186, 186);
    display: flex;
    flex-direction: column;
    align-items: center;
    .current-date {
        margin-bottom: 10px;
        font-size: 1rem;
    }
    .title {
        font-size: 0.9rem;
        margin-bottom: 8px;
        &.no-time-title {
            color: $primary-color;
            font-weight: 650;
        }
    }
    .slots {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        overflow-y: auto;
        padding: 10px 0;
        .slotTime {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 5px;              
        }
    }
    .show-more {
        margin-top: 8px;
    }
}