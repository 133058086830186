@import '../../index.scss';

.container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: $tertiary-color;
  
    .happy {
      height: calc(100vh - 40px);
      width: auto;
      margin: 20px 0 20px 20px;
      z-index: 999;
      position: relative;
    }
  
    .message {
      position: absolute;
      bottom: 100px;
      right: 100px;
      z-index: 1000;
      text-align: center;
      color: $light-color;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
  
      .logo {
        max-width: 400px; // Desktop size
      }
  
      p {
        font-size: 1.8rem;
        font-weight: bold;
        margin: 0;
        font-family: 'Arial', sans-serif;
      }
    }

    .background {
        width: 100vw;
        height: 100vh;

        .polygon {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transform-origin: top left;
            &.polygon-1 {
              background-color: $light-color;
              clip-path: polygon(22% 0, 60% 0, 0 80%, 0 30%);
              z-index: 1;
            }
        
            &.polygon-2 {
              background-color: $secondary-color;
              clip-path: polygon(60% 0%, 84% 0%, 11% 100%, 0 100%, 0 80%);
              z-index: 2;
            }
        
            &.polygon-3 {
              background-color: $accent-color;
              clip-path: polygon(100% 0%, 100% 12%, 32% 100%, 11% 100%, 84% 0);
              z-index: 3;
            }
        
            &.polygon-4 {
              background-color: $primary-color;
              clip-path: polygon(100% 100%, 32% 100%, 100% 12%);
              z-index: 4;
            }
          }
    }
  

  
    // Combined styles for tablets and mobile
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .happy {
        width: auto;
        height: 70vh; // Common size for tablet and mobile
        margin: 0;
      }
  
      .message {
        position: relative;
        bottom: auto;
        right: auto;
        margin-top: 20px;
        text-align: center;
  
        .logo {
          max-width: 350px; // Common size for tablet and mobile
        }
  
        p {
          font-size: 1.6rem; // Common text size for tablet and mobile
        }
      }
    }
  
    // Mobile-specific adjustments (logo and text size only)
    @media (max-width: 768px) {
      .message {
        .logo {
          max-width: 220px; // Adjust logo size only for mobile
        }
  
        p {
          font-size: 1.2rem; // Adjust text size only for mobile
        }
      }
    }
  }
  