@import '../index.scss';

.confirmation-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; // Full height of the viewport
    width: 100vw;  // Full width of the viewport
    background-color: #f0f4f8; // Light background for a clean look
    text-align: center;
  
    h1 {
      font-size: 2.5rem;
      margin-bottom: 16px;
      color: $primary-color; // Dark text color for readability
      font-family: 'Arial', sans-serif;
    }
  
    p {
      font-size: 1.2rem;
      color: $secondary-color;;
      font-family: 'Arial', sans-serif;
    }
  
    // Optional: Animation for entry effect
    animation: fadeIn 0.5s ease-in-out;
  
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  

.calendar-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $dark-color;
    position: relative; 
    background-color: $light-color;
    overflow: hidden;
  
    
    .header {
        background-color: $dark-color;
        color: $light-color;
        padding: 60px 0 40px 0;
        width: 100%;
        text-align: center;
        font-size: 2rem;
        font-weight: 800;
        position: relative;
        z-index: 1;
        height: 50vh; 
    }
    .body {
        display: flex;
        flex-direction: row;
        gap: 10px;
        background-color: $light-color;
        border-radius: 8px;
        position: relative; 
        z-index: 2;
        margin-top: -35vh; 
        padding: 20px; 
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        height: 440px;
        overflow: auto;
        // Media query for mobile
        @media (max-width: 768px) {
          flex-direction: column; // Change to column layout on smaller screens
          height: auto;
        }
    }
}