@import '../../index.scss';

.calendar-container {
  display: flex;
  flex-direction: column;
  max-width: 498px;
  margin: 20px 0;

  .calendar-month-year-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .calendar-month-arrow {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  
  .calendar-month-label {
    display: flex;
    justify-content: center;
    align-items: center;
  
    span {
      font-weight: 650;
      color: black;
    }
  }
  
  .calendar-date-row,
  .calendar-header-row {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }
  
  .calendar-header-row {
    margin-bottom: 10px;
  }
  
  .calendar-header-day {
    width: 42px;
    text-align: center;
    margin: 8px;
    color: #434343;
  }
  
  .calendar-cell {
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 8px;
    color: #434343;

    &.selected {
      background-color: #130058 !important; // Use global variable
      border-radius: 50%;
      color: $light-color; // Use global variable
    }
  
    &.available {
      background-color: $secondary-color; /* Lighter text color */
      color: $light-color; /* Lighter text color */
      cursor: pointer; /* Disable pointer */
      border-radius: 50%;
    }
    &.unavailable {
      color: #aaa; /* Lighter text color */
      cursor: not-allowed; /* Disable pointer */
    }
  
    &:hover {
      background-color: $secondary-color; // Use global variable
      border-radius: 50%;
      color: $light-color; // Use global variable
    }
  }
    // Media query for mobile
    @media (max-width: 768px) {
      max-width: 100%; // Allow full width on smaller screens
      padding: 0 10px; // Add some padding for spacing
    }
}

