@import '../../index.scss';

/* Error screen container */
.error-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $secondary-color; /* Light red background for error indication */
  color: $dark-color; /* Dark red text color */
  text-align: center;
  padding: 20px;
  
  /* Error content box */
  .error-content {
    max-width: 600px;
    background-color: $light-color;
    border: 1px solid #f5c6cb; /* Red border */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    h1 {
      color: $primary-color;
    }
    p {
      color: $dark-color;
      font-weight: 600;
    }
  }
}

