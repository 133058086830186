$primary-color: #002F6C;
$secondary-color: #2674B9;
$tertiary-color: #90E0EF;
$accent-color: #004EA8;
$warning-color: #FF6500;
$danger-color: #FF204E;
$light-color: #e2e2e4;
$dark-color: #333636;
$black-color: #000000;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  color: $dark-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
