@import '../../index.scss';

// Button.scss
button {
 padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.363); // Add shadow effect on hover

  &:hover {
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Add shadow effect on hover
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.2); // Add focus outline
  }

  &:active {
    box-shadow: none; // Remove shadow on active click
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.btn {
    &.primary {
      background-color: $primary-color;
      color: $light-color;
  
      &:hover {
        background-color: darken($primary-color, 10%);
      }
  
      &:active {
        background-color: darken($primary-color, 20%);
      }
    }
  
    &.secondary {
      background-color: $secondary-color;
      color: $light-color;
  
      &:hover {
        background-color: darken($secondary-color, 10%);
      }
  
      &:active {
        background-color: darken($secondary-color, 20%);
      }
    }
  
    &.danger {
      background-color: $danger-color;
      color: $light-color;
  
      &:hover {
        background-color: darken($danger-color, 10%);
      }
  
      &:active {
        background-color: darken($danger-color, 20%);
      }
    }
  
    &.warning {
      background-color: $warning-color;
      color: $light-color;
  
      &:hover {
        background-color: darken($warning-color, 10%);
      }
  
      &:active {
        background-color: darken($warning-color, 20%);
      }
    }
  
    &.info {
      background-color: $light-color;
      color: $dark-color;
  
      &:hover {
        background-color: darken($light-color, 10%);
      }
  
      &:active {
        background-color: darken($light-color, 20%);
      }
    }
}
  